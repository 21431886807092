import React from 'react';
import '../index.css';

function Services() {
  return (
    <div className="bg-zinc-100">
      <section className="container mx-auto max-w-[1240px] text-zinc-800 bg-zinc-100 md:px-6">
        <div className="px-5 py-20 mx-auto">
          <h2 className="pb-4 text-4xl font-bold text-zinc-900 text-center font-questrial uppercase">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 font-medium text-xl text-center">
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/images/chair-icon.png" alt="Dental Chair" className="w-24 h-24 mb-4 mx-auto" /><br/>
              <b className='uppercase'>Dental Chair</b><br/><br/> 
              Professional attention to dental chairs, ensuring patient comfort and operational efficiency.
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/images/hand-icon.png" alt="Dental Chair" className="w-24 h-24 mb-4 mx-auto" /><br/> 
              <b className='uppercase'>Handpiece</b><br/><br/> 
              Repairs for all types of dental handpieces, ensuring smooth and efficient operation for dental practices.
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/images/xray-icon.png" alt="Dental Chair" className="w-24 h-24 mb-4 mx-auto" /><br/>
              <b className='uppercase'>X-Ray</b><br/><br/> 
              Expert care for dental X-ray machines, ensuring clear and accurate images for diagnostic needs.
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/images/steril-icon.png" alt="Dental Chair" className="w-24 h-24 mb-4 mx-auto" /><br/> 
              <b className='uppercase'>Autoclave</b><br/><br/> 
              We ensure autoclaves function at peak performance, providing reliable sterilization for medical and dental instruments.
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/images/compres-icon.png" alt="Dental Chair" className="w-24 h-24 mb-4 mx-auto" /><br/> 
              <b className='uppercase'>Compressor</b><br/><br/> 
              Specialized in maintaining dental compressors to deliver consistent and reliable air pressure for equipment.
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/images/vacum-icon.png" alt="Dental Chair" className="w-24 h-24 mb-4 mx-auto" /><br/> 
              <b className='uppercase'>Suction Pump</b><br/><br/> 
              Service suction pumps to provide effective and reliable performance for dental procedures.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;