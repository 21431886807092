import React from 'react';
import { Link } from 'react-scroll';
import '../index.css';

const Navbar = () => {
  return (
    <div className="w-full bg-transparent absolute top-0 left-0 z-50">
      <div className='container bg-transparent flex flex-wrap justify-between items-center max-w-[1240px] mx-auto px-6 py-6 text-zinc-50'>
        {/* Logo */}
        <img src="./logos/logo_nobg.svg" alt="Sanero Logo" className="object-cover max-h-12 md:max-h-14 lg:max-h-16"/>

        {/* Mobile Contact Us Button */}
        <div className='flex'>
          <Link 
            to="contacts" 
            smooth={true} 
            duration={500} 
            className='p-4 cursor-pointer duration-300 border border-[#FF4D9A] sm:border-transparent hover:border-[#FF4D9A] rounded-xl'
          >
            Contact us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;