import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const items = [
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/" },
  { name: "Twitter", icon: FaTwitter, link: "https://twitter.com/" },
  { name: "LinkedIn", icon: FaLinkedin, link: "https://linkedin.com/" },
];

const Footer = () => {
  return (
    <div className="container min-w-full bg-[#434247]">
      <div className="container mx-auto justify-between bg-[#434247] text-zinc-50 py-y px-2">
        <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-[#FF4D9A] py-8">
          {/* Add Footer content */}
        </div>
        <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-zinc-50">
          <p className="py-4">2024 Sanero All Rights Reserved</p>
          <div className="flex justify-between sm:w-[126px] pt-4 text-2xl">
          {items.map((item) => (
        <a
          key={item.name}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <item.icon key={item.index} className="hover:text-zinc-300" />
        </a>
      ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
