function HeroSection() {
  return (
    <div className="relative w-full h-screen">
      <video 
        className="absolute top-0 left-0 w-full h-full object-cover" 
        src="./video/vecteezy_futuristic-digital-circuit-line_9884664.mp4" 
        autoPlay 
        loop 
        muted 
        playsInline
      />
      <div className="relative w-full bg-zinc-800 bg-opacity-70 flex flex-col items-center justify-center h-full">
        <div className="mx-auto max-w-[1240px] px-6">
          <h2 className="mt-10 text-3xl md:text-4xl lg:text-5xl text-zinc-50 font-questrial uppercase">
          Dental Devices Repairs<br/>for Peak Performance
          </h2>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;